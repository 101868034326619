// Source: https://www.industrialempathy.com/perfect-ish-font-fallback/?font=Averia%20Serif%20Libre

@font-face {
	font-family: 'Averia Serif Libre-fallback';
	size-adjust: 103.5%;
	ascent-override: 87%;
	src: local('Arial');
}

$black: #010101;
$grey: rgba(black, 0.5);
$grey-light: #e3e3e3;
$white: white;

$primary: #4da0b0;
$secondary: #d39d38;

$font-size: 16;
$line-height: 24;
$body-font: 'Averia Serif Libre', 'Averia Serif Libre-fallback', serif;

$grid-width: 600px;

$anim-time: 1s;
$anim-curve: cubic-bezier(0.5, 0.5, 0, 1);
$loading-time: 15s;

$z-0: 0; // Page
$z-1: 100; // Page
$z-2: 200; // Page
$z-3: 300; // Page
$z-4: 400; // Alerts
$z-5: 500; // Navigation
$z-6: 600; // Header
$z-7: 700; // Takeover
$z-8: 800; // Flyout
$z-9: 900; // Toast
$z-10: 9999; // System

$md-min: 768px;
$sm-max: $md-min - 1;
