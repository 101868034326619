.button {
	background-color: $primary;
	border: none;
	display: inline-flex;
	align-items: center;
	line-height: inherit;
	border-radius: rhythm(1);
	transition: transform $anim-time $anim-curve;
	padding: rhythm(2) rhythm();
	@include link($white, $white);

	svg {
		width: auto;
		height: 1em;
		margin-left: rhythm(2);
	}

	@media (hover) {
		&:hover {
			text-decoration: underline;
			text-underline-offset: 0.25ex;
		}
	}
}

.button--loading {
	position: relative;
	overflow: hidden;

	&:after {
		content: '';
		background-color: $grey;
		position: absolute;
		top: 0;
		left: -10%;
		width: 110%;
		height: 100%;
		animation: loading $anim-time * 2 $anim-curve infinite;
		z-index: -1;
	}
}
