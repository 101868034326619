.margin-bottom {
	display: flex;
	margin-bottom: rhythm();
}

.blur {
	position: absolute;
	width: 100%;
	height: 100%;
	filter: blur(rhythm(8));
	z-index: -1;
}
