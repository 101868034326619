.carousel {
	position: relative;
}

.carousel__viewport {
	overflow: hidden;
	width: 100%;

	&.is-draggable {
		cursor: move;
		cursor: grab;
	}

	&.is-dragging {
		cursor: grabbing;
	}
}

.carousel__tray {
	display: flex;
	width: 100%;

	> * {
		position: relative;
		flex: 0 0 100%;
	}
}

.carousel__dots {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;

	button {
		background: none;
		border: none;
		display: block;
		width: rhythm(7);
		height: rhythm(8);
		padding: rhythm(3) rhythm(2.5);

		@media (hover) {
			&:hover:after {
				background: $primary;
			}
		}

		&:after {
			content: '';
			background-color: $grey;
			display: block;
			width: rhythm(2);
			height: rhythm(2);
			border-radius: 50%;
		}

		&.active:after {
			background: $secondary;
		}
	}
}

.carousel--dual .carousel__tray > * {
	flex: 0 0 50%;
	max-width: 50%;
}
