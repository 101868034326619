.face {
	position: relative;
	width: 100%;
	opacity: 0;
	margin: auto;

	> img {
		width: 100%;
	}

	&.animating {
		animation: fadeIn $loading-time forwards;

		.face__part:nth-child(2) {
			animation: clockwise $loading-time $anim-curve forwards;
			z-index: $z-1;
		}

		.face__part:nth-child(3) {
			animation: antiClockwise $loading-time $anim-curve forwards;
		}
	}

	&.animated {
		animation-duration: unset !important;

		.face__part {
			animation-duration: unset !important;
		}

		.face__pause {
			opacity: 1;
			visibility: visible;
		}
	}
}

.face__part {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	transform-origin: 66.7% 42.8%;

	img {
		width: 100%;
	}
}

.face__audio {
	position: fixed;
	bottom: rhythm(2);
	left: rhythm(2);
	z-index: $z-1;
	// opacity: 0;
	// visibility: hidden;
	// transition: all $anim-time $anim-curve;

	@media (min-width: $md-min) {
		bottom: rhythm();
		left: rhythm();
	}
}

.face__pause {
	background: none;
	border: none;
	position: absolute;
	top: 0;
	left: 0;
	z-index: $z-1;
	opacity: 0;
	visibility: hidden;
	transition: all $anim-time $anim-curve;
	padding: rhythm(2);

	svg {
		width: rhythm(5);
		height: rhythm(5);
	}

	@media (hover) {
		&:hover {
			transform: scale(1.1);
		}

		&:active {
			transform: none;
			transition: none;
		}
	}
}
