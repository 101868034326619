.splash {
	background-color: $white;
	display: flex;
	flex-direction: column;
	position: fixed;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: $z-6;

	&.accepted {
		visibility: hidden;
		opacity: 0;
		transition: all $loading-time $anim-curve;

		.splash__content {
			transform: scale(0.9);
			filter: blur(rhythm(1));
			transition: all $loading-time $anim-curve;
		}
	}
}

.splash__content {
	max-width: $grid-width;
	max-height: 100%;
	overflow-y: auto;
	padding: rhythm();
	margin: auto;
}
