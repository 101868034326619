.beacon {
	background: none;
	border: none;
	position: absolute;
	width: rhythm(10);
	height: rhythm(10);
	border-radius: 50%;
	z-index: $z-2;
	transform: translate(-50%, -50%);
	transition: background-color $anim-time $anim-curve;
	padding: 0;

	svg {
		color: $white;
		width: rhythm(3);
		height: rhythm(3);
		animation: fadeIn $anim-time * 2 forwards;

		@media (min-width: $md-min) {
			width: rhythm(5);
			height: rhythm(5);
		}
	}

	&:after {
		content: '';
		background-color: $primary;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 50%;

		animation-name: pulse;
		animation-duration: $anim-time * 2;
		animation-timing-function: $anim-curve;
		animation-iteration-count: infinite;
	}

	&:hover {
		background-color: rgba($secondary, 0.5);
	}

	&:active {
		background-color: rgba($secondary, 0.7);
		transition: none;
	}

	&:nth-of-type(1) {
		top: 39.6%;
		left: 25.5%;
	}

	&:nth-of-type(2) {
		top: 18.2%;
		left: 52.4%;
	}

	&:nth-of-type(3) {
		top: 39%;
		left: 86%;
	}

	&:nth-of-type(4) {
		top: 13.2%;
		left: 82.2%;
	}

	&:nth-of-type(5) {
		top: 64.5%;
		left: 61.4%;
	}

	&:nth-of-type(6) {
		top: 65%;
		left: 40.7%;
	}

	&:nth-of-type(7) {
		top: 81.4%;
		left: 79.4%;
	}

	&:nth-of-type(5) svg,
	&:nth-of-type(7) svg {
		filter: drop-shadow(1px 1px 1px $grey);
	}

	&.viewed {
		mix-blend-mode: difference;

		&:after {
			animation: none;
			opacity: 0.5;
		}
	}

	@media (min-width: $md-min) {
		width: rhythm(20);
		height: rhythm(20);
	}
}
