.modal {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	visibility: hidden;
	opacity: 0;
	z-index: $z-7;
	transition: all $anim-time $anim-curve;
	padding: rhythm();

	&.active {
		visibility: visible;
		opacity: 1;

		.modal__content {
			transform: none;
		}
	}

	@media (min-width: $md-min) {
		padding-top: 10vh;
		padding-bottom: 10vh;
	}
}

.modal__mask {
	background-color: $grey;
	border: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.modal__content {
	background-color: rgba($white, 0.9);
	position: relative;
	width: 100%;
	max-height: 100%;
	max-width: $grid-width;
	transform: translateY(rhythm());
	transition: transform $anim-time $anim-curve;
	border-radius: rhythm(1);
	overflow-y: auto;
	padding: rhythm();
	margin: auto;
}

.modal__close {
	background: $grey-light;
	border: none;
	position: sticky;
	top: rhythm(-4);
	float: right;
	border-bottom-left-radius: rhythm(1);
	z-index: $z-1;
	padding: rhythm(1);
	margin-top: rhythm(-4);
	margin-right: rhythm(-4);
	@include link;
}
