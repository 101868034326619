.alert {
	background: $white;
	display: none;
	align-items: center;
	justify-content: center;
	text-align: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: $z-9;
	padding: rhythm();

	@media screen and (orientation: portrait) {
		display: flex;
	}
}
