@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: scale(1.1);
	}
	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes clockwise {
	0% {
		opacity: 0;
		transform: rotate(-180deg);
	}
	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes antiClockwise {
	0% {
		opacity: 0;
		transform: rotate(180deg);
	}
	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes pulse {
	0% {
		opacity: 0;
		transform: scale(0);
	}
	50% {
		opacity: 0.5;
		transform: scale(1);
	}
	100% {
		opacity: 0;
	}
}

@keyframes loading {
	0% {
		transform: scaleX(0) skewX(-45deg);
		transform-origin: left;
	}
	50% {
		transform: none;
		transform-origin: left;
	}
	51% {
		transform-origin: right;
	}
	100% {
		transform: scaleX(0);
		transform-origin: right;
	}
}
