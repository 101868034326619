h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1;
	margin: 0 0 rhythm();

	&:last-child {
		margin-bottom: 0;
	}
}

p {
	margin: 0 0 rhythm();

	&:last-child {
		margin-bottom: 0;
	}
}

a {
	text-underline-offset: 0.25ex;
	@include link;
}
