html {
	overflow-x: hidden;

	// Source: https://medium.com/@MateMarschalko/improving-font-rendering-with-css-3383fc358cbc
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
}

body {
	color: $black;
	font-family: $body-font;
	font-size: math.div($font-size, 16) + rem;
	line-height: math.div($line-height, 16) + rem;
}

main {
	position: relative;
	display: flex;
	min-height: 100vh;
	overflow: hidden;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

img,
svg {
	vertical-align: top;
	max-width: 100%;
	height: auto;
}

button {
	cursor: pointer;
}

div[style*='inline-block'] {
	vertical-align: top;
}

ul[class],
ol[class] {
	list-style: none;
	padding: 0;
	margin: 0;
}

audio {
	display: block;
	border-radius: rhythm();
	max-height: rhythm(8);
	@include depth(1);
}

[disabled] {
	opacity: 0.5;
	pointer-events: none;
}
